<template>
  <div :class="$style.container">
    <layout-mobile-page-header
      v-if="$matchMedia.down('sm')"
      default-path="/profile"
    >
      {{ t('Settings') }}
    </layout-mobile-page-header>

    <ui-spinner v-if="fetchData.pending.value" :class="$style.spinner" />

    <div v-else :class="$style.content">
      <profile-edit-avatar :class="$style.avatar" :allow-mp4="allowMP4" />
      <profile-edit-style :class="$style.style" />
      <profile-forms-contacts id="contacts" :class="$style.contacts" />
      <profile-forms-delivery id="delivery" :class="$style.delivery" />
      <profile-notifications :class="$style.notify" />

      <ui-button
        v-if="$matchMedia.down('sm')"
        theme="primary"
        fill
        size="medium"
        to="/profile/settings/task"
      >
        {{ t('Task settings') }}
      </ui-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "metaTitle": "Profile Settings - PARI Pass"
  },
  "ru": {
    "metaTitle": "Настройки профиля - PARI Pass",
    "Settings": "Настройки профиля",
    "Task settings": "Настройки заданий"
  }
}
</i18n>

<script setup>
import { onMounted, ref } from 'vue'
import ProfileEditAvatar from '~/components/profile/edit/avatar'
import ProfileEditStyle from '~/components/profile/edit/style'
import ProfileFormsContacts from '~/components/profile/forms/contacts'
import ProfileFormsDelivery from '~/components/profile/forms/delivery'
import ProfileNotifications from '~/components/profile/notifications'
import LayoutMobilePageHeader from '~/components/layout/mobile/page-header'
import UiButton from '~/components/ui/button'
import UiSpinner from '~/components/ui/spinner'
import { useProfileDataStore } from '~/stores/profile/data'
import { useNotificationsSubscriptionsListStore } from '@/stores/notifications/subscriptions-list.js'

const { t } = useI18n()
const { $scrollToHash, $matchMedia, $sdk } = useNuxtApp()
const profileDataStore = useProfileDataStore()
const notificationsSubscriptionsListStore =
  useNotificationsSubscriptionsListStore()

const allowMP4 = ref(false)

const fetchData = await useAsyncData(
  () =>
    Promise.all([
      profileDataStore.fetchData(),
      notificationsSubscriptionsListStore.fetchItems()
    ]),
  { server: false }
)

onMounted(async () => {
  $scrollToHash()

  const { items: privileges } = await $sdk
    .module('privileges')
    .then(({ privileges: { getItems } }) =>
      getItems({
        'filter[type]': 'avatarAnimation'
      })
    )

  allowMP4.value = !!privileges[0]
})

useHead({
  title: t('metaTitle'),
  meta: [{ hid: 'og:title', name: 'og:title', content: t('metaTitle') }]
})
</script>

<style lang="scss" module>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content {
  display: grid;
  grid-template-columns: 32em 1fr;
  grid-gap: 3.8em;
  @include down(md) {
    grid-template-columns: 1fr;
    grid-gap: 3em;
    .contacts {
      grid-row: unset;
    }
    .avatar,
    .style,
    .contacts,
    .delivery,
    .notify {
      grid-column: 1;
    }
  }
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  margin-bottom: -1.8em;
}
.contacts {
  grid-row: 1 / span 2;
  grid-column: 2;
}
.delivery {
  grid-column: 1 / 3;
}
.notify {
  grid-column: 1 / 3;
}
</style>
