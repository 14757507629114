<template>
  <div :class="$style.container">
    <div :class="$style.heading">
      <div :class="$style.text">{{ t('heading') }}</div>
    </div>
    <div :class="$style.styles" @click="onOpen">
      <ui-avatar
        v-for="(border, i) in borders"
        size="custom"
        :key="i"
        :class="$style.avatar"
        :src="$auth.account.avatar"
        :border="border"
      />
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "heading": "Stylize Profile"
  },
  "ru": {
    "heading": "Стилизовать профиль"
  }
}
</i18n>

<script setup>
import UiAvatar from '~/components/ui/avatar'
import { EDIT_DIALOG_ID } from './consts'

const { $dialog } = useNuxtApp()
const { t } = useI18n()

const borders = [
  [20, '#FFE34F', '#27FF88'],
  '#8878FF',
  [20, '#FF4AB7', '#A270DB'],
  [20, '#FF3EC6', '#1091FF'],
  '#5AC3C5'
]

const onOpen = () =>
  $dialog({
    content: defineAsyncComponent(
      () => import('~/components/profile/edit/style/edit')
    ),
    wrapperProps: {
      padless: true
    },
    id: EDIT_DIALOG_ID
  })
</script>

<style lang="scss" module>
.container {
  background-color: var(--t-app-l2-bg);
  border-radius: 0.6em;
  padding: 2em 3em 2.8em;
}
.heading {
  margin-bottom: 2.4em;
  .text {
    font-weight: 500;
    font-size: 1.3em;
    line-height: 154%;
    text-transform: uppercase;
    color: #acb0d5;
  }
}
.styles {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.avatar {
  width: 7em;
  height: 7em;
}
.avatar + .avatar {
  margin-right: -2.3em;
}
@media (max-width: 568px) {
  .container {
    padding: 2.3em 2em;
  }
  .avatar + .avatar {
    margin-right: -1.3em;
  }
}
</style>
