<template>
  <form
    data-form="profile-contacts"
    :class="$style.form"
    @submit.prevent="onSubmit"
  >
    <div :class="$style.heading">
      <div :class="$style.text">{{ t('heading') }}</div>
    </div>
    <ui-field
      :class="[$style.field, $style.username]"
      v-model="form.username"
      :label="t('username')"
      :error="errors.username"
    />
    <ui-field
      :class="[$style.field, $style.phone]"
      v-model="form.phone"
      :label="t('phone')"
      :error="errors.phone"
    />
    <ui-field
      data-form-field="email"
      :class="[$style.field, $style.email]"
      v-model="form.email"
      :label="t('email')"
      :error="errors.email"
    />
    <ui-field
      :class="[$style.field, $style.name]"
      v-model="form.name"
      :label="t('name')"
      :error="errors.name"
    >
      <template #description>
        <span>{{ t('nameDescription') }}</span>
      </template>
    </ui-field>
    <div :class="$style.actions">
      <ui-button
        :class="$style.submit"
        :loading="loading"
        type="submit"
        size="medium"
        fill
        theme="accent"
      >
        {{ t('save') }}
      </ui-button>
    </div>
  </form>
</template>

<i18n>
{
  "en": {
    "heading": "Self information",
    "name": "Full name",
    "nameDescription": "Full name is required to claim purchases",
    "username": "Nickname",
    "email": "E-mail",
    "phone": "Phone number",
    "save": "Save",
    "saved": "Saved"
  },
  "ru": {
    "heading": "Личная информация",
    "name": "ФИО",
    "nameDescription": "Полное имя нужно для получение призов",
    "username": "Nickname",
    "email": "E-mail",
    "phone": "Номер телефона",
    "save": "Сохранить",
    "saved": "Сохранено"
  }
}
</i18n>

<script>
import UiField from '~/components/ui/field'
import UiButton from '~/components/ui/button'
import { useProfileDataStore } from '~/stores/profile/data'

export default {
  name: 'ProfileFormsContacts',
  setup() {
    const { t } = useI18n()
    const profileDataStore = useProfileDataStore()
    const profileData = computed(() => profileDataStore.data)
    const updateData = async updates =>
      await profileDataStore.fetchUpdate(updates)
    return { t, profileData, updateData }
  },
  data() {
    return {
      loading: false,
      form: {
        name: '',
        username: '',
        phone: '',
        email: ''
      },
      errors: {
        name: '',
        username: '',
        phone: '',
        email: ''
      }
    }
  },
  components: {
    UiButton,
    UiField
  },
  methods: {
    updateForm() {
      if (this.profileData) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = this.profileData[key] || ''
        })
      }
    },
    showErrors(errors) {
      this.errors = errors
    },
    hideErrors() {
      this.errors = {}
    },
    async onSubmit() {
      if (this.loading) {
        return
      }
      try {
        this.loading = true
        this.hideErrors()
        await this.updateData({ updates: this.form })
        this.updateForm()
        this.$snacks.success(this.t('saved'))
      } catch (err) {
        if (err.response.status === 422) {
          this.showErrors(err.response._data)
        }
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.updateForm()
  }
}
</script>

<style lang="scss" module>
.form {
  display: grid;
  grid-template-columns: 3.35fr 5fr;
  grid-gap: 0 2em;
  background: var(--t-app-l2-bg);
  border-radius: 1.1em;
  padding: 2.4em 3em 2.4em;
  @include down(sm) {
    padding: 2em;
  }
}
.heading {
  margin-bottom: 1.2em;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  .text {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.7);
  }
}
.field {
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  padding-bottom: 0 !important;
}
.username {
}
.actions,
.username,
.name {
  grid-column: 1 / 3;
}
.submit {
  height: 5.4em;
  min-width: 30em;
  padding: 1.2em 3.2em;
  @include down(md) {
    width: 100%;
  }
}
@media (max-width: 568px) {
  .form {
    padding: 2em;
  }
  .phone,
  .email {
    grid-column: 1 / 3;
  }
}
</style>
