<template>
  <ui-block :class="$style.container" id="notifications" :title="t('heading')">
    <profile-notifications-list />
    <template v-if="0">
      <div :class="$style.title">
        <span :class="$style.text">{{ t('notify') }}</span>
      </div>
      <ul :class="$style.list">
        <li v-for="item in settings" :key="item.id" :class="$style.item">
          <ui-icon :name="item.icon" :class="$style.icon" />
          <div :class="$style.name">
            <span :class="$style.text">{{ item.name }}</span>
          </div>
          <div :class="$style.actions">
            <div :class="$style.mail" v-if="false">
              <div :class="$style.text">abc@gmail.com</div>
            </div>
            <ui-switcher :value="false" />
          </div>
        </li>
      </ul>
      <div :class="$style.actions">
        <ui-button
          :class="$style.submit"
          type="submit"
          theme="accent"
          size="medium"
          fill
        >
          {{ t('save') }}
        </ui-button>
      </div>
    </template>
  </ui-block>
</template>

<i18n>
{
  "ru": {
    "heading": "Настройка уведомлений",
    "notify": "Уведомления на сайте",
    "system": "Системные уведомления",
    "appearance": "Появление новых заданий или кодов",
    "completing": "Выполнение задания",
    "clan": "Клан (приглашение/кик из клана, инвайт на дуэль, получение ачивки в клане)",
    "choose": "Язык уведомлений",
    "save": "Сохранить"
  },
  "en": {
    "heading": "Notification settings",
    "notify": "Site notifications",
    "system": "System notifications",
    "appearance": "The appearance of new tasks or codes",
    "completing": "Completing a task",
    "clan": "Clan (invite/kick from clan, invite to duel, get achieve in clan)",
    "choose": "Notification Language",
    "save": "Сохранить"
  }
}
</i18n>

<script>
import UiBlock from '~/components/ui/block'
import UiButton from '~/components/ui/button'
import UiSwitcher from '~/components/ui/switcher'
import ProfileNotificationsList from '~/components/profile/notifications/list'
import UiIcon from '@/components/ui/icon/index.vue'

export default {
  name: 'ProfileNotifications',
  components: {
    UiIcon,
    UiBlock,
    ProfileNotificationsList,
    UiSwitcher,
    UiButton
  },
  data() {
    return {
      type: {},
      options: [
        {
          id: 'code',
          title: 'Code',
          active: false
        },
        {
          id: 'quiz',
          title: 'Quiz',
          active: false
        },
        {
          id: 'scopegg',
          title: 'ScopeGG',
          active: false
        }
      ]
    }
  },
  computed: {
    defaultTypeOption() {
      return {
        id: null,
        title: 'Уведомлять раз в день'
      }
    },
    settings() {
      return [
        { id: 'system', name: this.t('system'), icon: 'bell' },
        { id: 'appearance', name: this.t('appearance'), icon: 'bolt' },
        { id: 'completing', name: this.t('completing'), icon: 'check' },
        { id: 'clan', name: this.t('clan'), icon: 'bolt' }
      ]
    }
  },
  setup() {
    const { t } = useI18n()
    return { t }
  }
}
</script>

<style lang="scss" module>
.container {
}
</style>
