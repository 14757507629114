<template>
  <div
    :class="$style.container"
    v-ws-action.subscribe-notifications="onSubscribe"
  >
    <ul :class="$style.list">
      <li
        v-for="provider in providers"
        :key="provider.type"
        :class="$style.item"
      >
        <div :class="$style.name">
          <span :class="$style.text">{{ provider.title }}</span>
        </div>
        <div :class="$style.actions">
          <template v-if="accountStore.isConnected(provider.id)">
            <img
              style="display: none"
              :class="$style.status"
              src="~/assets/images/check-in.svg?url"
              alt=""
            />
            <ui-switcher
              @input="onChangeNotifyStatus(provider)"
              :value="provider.active"
            />
          </template>
          <ui-button
            :class="$style.connect"
            v-else
            @click="onLogin(provider)"
            theme="primary"
            size="small"
            fill
          >
            {{ t('Connect') }}
          </ui-button>
        </div>
      </li>
    </ul>
  </div>
</template>

<i18n>
{
  "ru": {
    "Connect": "Привязать"
  }
}
</i18n>

<script setup>
import UiButton from '~/components/ui/button'
import UiSwitcher from '~/components/ui/switcher'
import { useAccountStore } from '@/stores/account'
import { useNotificationsSubscriptionsStore } from '@/stores/notifications/subscriptions.js'
import { useNotificationsSubscriptionsListStore } from '@/stores/notifications/subscriptions-list.js'

const { t } = useI18n()
const { $sdk, $dialog } = useNuxtApp()
const accountStore = useAccountStore()
const notificationsSubscriptionsStore = useNotificationsSubscriptionsStore()
const notificationsSubscriptionsListStore =
  useNotificationsSubscriptionsListStore()

const providers = computed(() => notificationsSubscriptionsListStore.providers)

let dialog

const onChangeNotifyStatus = provider => {
  if (provider.status?.active) {
    notificationsSubscriptionsStore.unsubscribe(provider.id)
  } else {
    dialog = $dialog({
      content: defineAsyncComponent(
        () => import('~/components/profile/notifications/popup.vue')
      ),

      contentProps: {
        providerId: provider.id
      },

      wrapper: null,
      zIndex: 1010,

      fetchData: async () => {
        if (!provider.status) {
          await notificationsSubscriptionsStore.subscribe(provider.id)
        }
      }
    })
  }
}

const onLogin = async item => {
  await $sdk
    .module('auth')
    .then(({ login }) =>
      login({ social: item.id, redirectIfAuthorized: false })
    )
  await notificationsSubscriptionsListStore.fetchItems()
  await accountStore.fetchAccountAuths()
}

const onSubscribe = ({ source }) => {
  notificationsSubscriptionsListStore.updateItem(source, {
    status: { active: 1 }
  })

  if (dialog?.app) {
    dialog.close()
  }
}
</script>

<style lang="scss" module>
.container {
}

.list {
  padding: 0 2.2em 0;
  list-style: none;
  background: var(--t-form-bg);
  border-radius: 0.6em;

  > :not(:last-child) {
    border-bottom: 1px solid var(--t-app-div-color);
  }
}

.item {
  min-height: 6em;
  display: flex;
  align-items: center;
}

.name {
  flex: 1 1;

  .text {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 125%;
  }
}

.title {
  .text {
    font-weight: 500;
    font-size: 1.4em;
    line-height: 143%;
    color: #acb0d5;
  }
}

.actions {
  grid-column-start: 2;
  display: flex;
  align-items: center;
  @media (max-width: 568px) {
    grid-column-start: unset;
  }
}

.connect {
  padding: 0.6em 2.2em;
}

.status {
  margin-right: 1.6em;
  width: 3.4em;
  height: 3.4em;
}
</style>
