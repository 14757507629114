import { defineStore } from 'pinia'
import { useAccountStore } from '@/stores/account'

export const useProfileAvatarStore = defineStore('profile-avatar', {
  actions: {
    async upload(file) {
      const { $sdk } = useNuxtApp()
      const accountStore = useAccountStore()

      const formData = new FormData()
      formData.append('file', file)

      const data = await $sdk
        .module('profile')
        .then(({ uploadAvatar }) => uploadAvatar(formData))

      accountStore.updateAccount({ avatar: data })
    }
  }
})
