import { createItemsStore } from '@/utils/pinia/index.js'

export const useNotificationsSubscriptionsListStore = createItemsStore(
  'notifications-subscriptions-list',
  {
    path: '/api/v1/notifications/subscriptions',
    pagination: false,
    extends: {
      getters: {
        providers() {
          return (
            this.items
              // .filter(item => ['telegram', 'vkontakte''].includes(item.id))
              .map(item => ({
                ...item,
                active: item.status ? !!item.status.active : false
              }))
          )
        }
      }
    }
  }
)
