<template>
  <ui-block :title="t('heading')" :class="$style.container">
    <template #aside-bottom>
      <img
        :class="$style.icon"
        src="~/assets/images/stickers/head.svg?url"
        alt=""
      />
      <div :class="$style.description">
        <div :class="$style.text">{{ t('description') }}</div>
      </div>
    </template>

    <form :class="$style.form" @submit.prevent="onSubmit">
      <template v-if="0">
        <ui-field
          :class="[$style.field, $style.country]"
          v-model="form.country"
          :label="t('country')"
          :error="errors.country"
        />
        <ui-field
          :class="[$style.field, $style.city]"
          v-model="form.city"
          :label="t('city')"
          :error="errors.city"
        />
        <ui-field
          :class="[$style.field, $style.street]"
          v-model="form.street"
          :label="t('street')"
          :error="errors.street"
        />
        <ui-field
          :class="[$style.field, $style.house]"
          v-model="form.house_number"
          :label="t('house')"
          :error="errors.house_number"
        />
        <ui-field
          :class="[$style.field, $style.zip]"
          v-model="form.zipcode"
          :label="t('zip')"
          :error="errors.zipcode"
        />
      </template>

      <ui-field
        :class="[$style.field, $style.tradeoffer]"
        v-model="form.tradeoffer"
        :label="t('tradeoffer')"
        :error="errors.tradeoffer"
      >
        <template #description v-if="accountStore.isConnected('steam')">
          <ui-link :href="tradeOfferLinkHelp">{{ t('Get the link') }}</ui-link>
        </template>
      </ui-field>

      <div :class="$style.actions">
        <ui-button
          :class="$style.submit"
          :loading="loading"
          type="submit"
          theme="accent"
          size="medium"
          block
          fill
        >
          {{ t('save') }}
        </ui-button>
      </div>
    </form>
  </ui-block>
</template>

<i18n>
{
  "en": {
    "heading": "Contacts to deliver prizes",
    "description": "We will contact you after the end of the event to confirm the address.",
    "country": "Country",
    "city": "City",
    "street": "Street",
    "house": "House",
    "zip": "Zip code",
    "tradeoffer": "Tradelink",
    "save": "Save",
    "saved": "Saved"
  },
  "ru": {
    "heading": "Контакты для отправки призов",
    "description": "После завершения ивента мы свяжемся с тобой для подтверждения адреса.",
    "country": "Страна",
    "city": "Город",
    "street": "Улица",
    "house": "Дом / квартира",
    "zip": "индекс",
    "tradeoffer": "Укажи свой tradelink",
    "Get the link": "Получить ссылку",
    "save": "Сохранить",
    "saved": "Сохранено"
  }
}
</i18n>

<script>
export default {
  name: 'ProfileFormsDelivery'
}
</script>

<script setup>
import UiField from '~/components/ui/field'
import UiButton from '~/components/ui/button'
import UiBlock from '~/components/ui/block'
import UiLink from '~/components/ui/link'
import { useProfileDataStore } from '~/stores/profile/data'
import { useAccountStore } from '@/stores/account'

const { $snacks } = useNuxtApp()
const { t } = useI18n()
const profileDataStore = useProfileDataStore()
const accountStore = useAccountStore()

const loading = ref(false)
const form = ref({
  country: '',
  city: '',
  street: '',
  house_number: '',
  zipcode: '',
  tradeoffer: ''
})
const errors = ref({
  country: '',
  city: '',
  street: '',
  house_number: '',
  zipcode: '',
  tradeoffer: ''
})

const profileData = computed(() => profileDataStore.data)
const steamId = computed(() => accountStore.findSourceId('steam'))
const tradeOfferLinkHelp = computed(
  () =>
    `https://steamcommunity.com/profiles/${steamId.value}/tradeoffers/privacy#trade_offer_access_url`
)

const updateForm = () => {
  if (profileData.value) {
    Object.keys(form.value).forEach(key => {
      form.value[key] = profileData.value[key] || ''
    })
  }
}

const showErrors = value => {
  errors.value = value
}
const hideErrors = () => (errors.value = {})
const onSubmit = async () => {
  if (loading.value) return

  try {
    loading.value = true
    hideErrors()
    await profileDataStore.fetchUpdate({ updates: form.value })
    updateForm()
    $snacks.success(t('saved'))
  } catch (err) {
    if (err.response.status === 422) {
      showErrors(err.response._data)
    }
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  updateForm()
})
</script>

<style lang="scss" module>
.container {
  position: relative;
}
.icon {
  width: 9em;
  height: 9em;
  margin-bottom: 1em;
  @include down(md) {
    position: absolute;
    top: -3em;
    right: 1em;
    margin-bottom: 0;
  }
}
.description {
  margin-right: 10em;
  margin-bottom: 0.8em;
  .text {
    font-size: 1.4em;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #ffffff;
  }
}
.form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 2em;
  @include down(md) {
    align-items: end;
    grid-template-columns: repeat(2, 1fr);
  }
  @include down(sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.field {
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  padding-bottom: 1.5em !important;

  &.country,
  &.city,
  &.street {
    grid-column: span 2;
  }
  &.zip,
  &.house {
    grid-column: span 1;
  }
  &.tradeoffer {
    grid-column: 1 / -1;
  }
}
.actions {
  text-align: right;
  grid-column: span 2;
}
.submit {
  height: 5.4em;
  min-width: 30em;
  padding: 1.2em 3.2em;
  @include down(md) {
    width: 100%;
  }
}
</style>
