import { defineStore } from 'pinia'
import { useApiClient } from '@/composables/api-client'
import { useNotificationsSubscriptionsListStore } from '@/stores/notifications/subscriptions-list.js'

export const useNotificationsSubscriptionsStore = defineStore(
  'notifications-subscriptions',
  {
    actions: {
      async subscribe(id) {
        const notificationsSubscriptionsListStore =
          useNotificationsSubscriptionsListStore()
        const apiClient = useApiClient()
        const res = await apiClient.post(
          `/api/v1/notifications/subscriptions/${id}`
        )
        notificationsSubscriptionsListStore.updateItem(id, { status: res })
        return res.data
      },
      async unsubscribe(id) {
        const notificationsSubscriptionsListStore =
          useNotificationsSubscriptionsListStore()
        const apiClient = useApiClient()
        await apiClient.delete(`/api/v1/notifications/subscriptions/${id}`)
        notificationsSubscriptionsListStore.updateItem(id, { status: null })
        // commit('list/UPDATE_ITEM', {id, status: null})
      }
    }
  }
)
