<template>
  <div :class="$style.container">
    <div :class="$style.heading">
      <div :class="$style.text">{{ t('avatar') }}</div>
    </div>
    <div :class="$style.body">
      <div :class="$style.avatarContainer" @click="selectFile">
        <ui-avatar
          size="custom"
          :class="$style.avatar"
          :src="$auth.account.avatar"
          :sticker="$auth.account.styles.sticker"
          :border="$auth.account.styles.avatarBorderColor"
        />
        <button :class="$style.change" :disabled="uploading">
          <img src="~/assets/images/picture.svg?url" alt="" />
        </button>
        <ui-file
          :class="$style.file"
          :size="1000000"
          :mp4="allowMp4"
          :disabled="uploading"
          ref="file"
          @input="onChangeAvatar"
        />
      </div>
      <ui-button
        :class="$style.button"
        @click="selectFile"
        :loading="uploading"
        theme="gray"
        block
        fill
      >
        {{ t('upload') }}
      </ui-button>
      <div :class="$style.description">
        <div :class="$style.text">
          {{ t('Image') }} JPEG {{ t('or') }} PNG {{ t('under') }} 1{{
            t('Mb')
          }}
        </div>
        <div :class="$style.text" v-if="allowMp4">
          {{ t('Video') }} MP4 {{ t('under') }} 1{{ t('Mb') }}
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "avatar": "Avatar",
    "upload": "Upload new",
    "description": "Image JPEG or PNG under 1 Mb"
  },
  "ru": {
    "avatar": "Аватар",
    "upload": "Загрузить новый",
    "Image": "Изображение",
    "Video": "Видео",
    "or": "или",
    "description": "Изображение JPEG или PNG до 1 Мб",
    "under": "до",
    "Mb": "Мб"
  }
}
</i18n>

<script>
import UiAvatar from '~/components/ui/avatar'
import UiFile from '~/components/ui/file'
import UiButton from '~/components/ui/button'
import { useProfileAvatarStore } from '@/stores/profile/avatar.js'

export default {
  name: 'ProfileEditAvatar',
  components: {
    UiButton,
    UiFile,
    UiAvatar
  },
  props: {
    allowMp4: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploading: false
    }
  },
  methods: {
    selectFile() {
      if (!this.uploading) {
        this.$refs.file.select()
      }
    },
    async onChangeAvatar(file) {
      try {
        this.uploading = true
        await this.upload(file)
      } catch (e) {
      } finally {
        this.uploading = false
      }
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
      upload: useProfileAvatarStore().upload
    }
  }
}
</script>

<style lang="scss" module>
.container {
  background-color: var(--t-app-l2-bg);
  border-radius: 0.6em;
  padding: 2.4em 3em 2em;
}
.heading {
  margin-bottom: 1.4em;
  font-family: var(--primary-font);
  font-style: var(--primary-font-style);
  .text {
    font-weight: 500;
    font-size: 1.6em;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.7);
  }
}
.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatarContainer {
  position: relative;
  width: 15em;
  height: 15em;
}
.avatar {
  width: 100%;
  height: 100%;
}
.button {
  margin-top: 2.8em;
  text-transform: uppercase;
}
.change {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: linear-gradient(
    0deg,
    rgba(10, 14, 41, 0.7),
    rgba(10, 14, 41, 0.7)
  );
  img {
    width: 4.8em;
    height: 4.8em;
  }
}
.file {
  display: none;
}
.description {
  margin-top: 1.3em;
  text-align: center;
  .text {
    font-size: 1.3em;
    line-height: 130%;
    color: #acb0d5;
  }
}
@media (max-width: 568px) {
  .container {
    padding: 2em;
  }
}
</style>
